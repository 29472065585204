import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import App from './App.vue'

import router from './router'
import store from './store'
import i18n from './i18n'

import _ from 'lodash'
import validator from 'validator'
import ElementPlus from 'element-plus'
import VueSmoothScroll from 'vue3-smooth-scroll'

import { Loading } from '@element-plus/icons'

import { VueCookieNext } from 'vue-cookie-next'
import { createDeviceDetector } from "next-vue-device-detector"

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import globalMixin from './mixins/global'

import 'normalize.css/normalize.css'
import './assets/element-plus-theme/element-variables.scss'

library.add(fas)

const app = createApp(App)
                .use(i18n)
                .use(store)
                .use(router)
                .use(ElementPlus)
                .use(createHead())
                .use(VueCookieNext)
                .use(VueSmoothScroll)
                .use(createDeviceDetector())
                .component('font-awesome-layers-text', FontAwesomeLayersText)
                .component('font-awesome-layers', FontAwesomeLayers)
                .component('font-awesome-icon', FontAwesomeIcon)
                .component('loading', Loading)

app.config.globalProperties.$validator = validator
app.config.globalProperties.$_ = _

app.mixin(globalMixin)

app.mount('#app')
