import { createStore } from 'vuex'

import SecureLS from "secure-ls"
import createPersistedState from 'vuex-persistedstate'

import globals from './modules/globals'
import session from './modules/session'

import data from './modules/database/data'
import menu from './modules/database/menu'
import page from './modules/database/page'
import users from './modules/database/users'
import media from './modules/database/media'
import widget from './modules/database/widget'
import settings from './modules/database/settings'
import websiteInfo from './modules/database/website-info'
import authentication from './modules/database/authentication'

var ls = new SecureLS({ encodingType: 'aes', isCompression: false })

export default createStore({  
  plugins: [createPersistedState({
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  modules: {
    data,
    menu,
    page,
    users,
    media,
    widget,
    globals,
    session,    
    settings,
    websiteInfo,
    authentication,    
  }
})
