import axios from 'axios'

import { JSON_API_BASE_URL, DATABASE_API_BASE_URL, API_REQUEST_HEADERS } from '@/utils/constants'

const state = () => ({
    previousRouteName: null,
    nextRouteName: null,
    
    widgetsJSONData: {},    
    sectionsJSONData: {}
})

const getters = {
    
}

const mutations = {
    updateNextRouteName(state, r){

        state.nextRouteName = r
        
    },
    updatePreviousRouteName(state, r){

        state.previousRouteName = r
        
    }
}

const actions = {
    async loadJSONData({ state }){

        try {
            
            let task1 = await axios.request({
                method: 'get',
                baseURL: JSON_API_BASE_URL,
                url: '/sections',
                headers: API_REQUEST_HEADERS
            });
            let task2 = await axios.request({
                method: 'get',
                baseURL: JSON_API_BASE_URL,
                url: '/widgets',
                headers: API_REQUEST_HEADERS
            })
            
            state.sectionsJSONData = task1.data
            state.widgetsJSONData = task2.data

        } catch (err) {
            
            if (process.env.NODE_ENV === 'development') {

                if (error.response) {
                
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                } else if (error.request) {
                
                    console.log(error.request);
                
                } else {
                
                    console.log('Error', error.message);
                
                }
                
                console.log(error.config);

            }

        }

    },
    async addItemInDB({ dispatch }, payload){

        let task = await dispatch('makeRequestToDB', { 
            url: `/${payload.context}/add`,
            method: 'post',
            data: payload.data 
        })

        return task

    },
    async deleteItemInDB({ dispatch }, payload){

        let task = await dispatch('makeRequestToDB', { 
            url: `/${payload.context}/delete/${payload.id}`,
            method: 'delete' 
        })

        return task

    },
    async updateItemInDB({ dispatch }, payload){

        let task = await dispatch('makeRequestToDB', { 
            url: `/${payload.context}/update/${payload.id}`,
            method: 'put',
            data: payload.data 
        })

        return task

    },
    async getAllItemsFromDB({ dispatch }, payload){

        let task = await dispatch('makeRequestToDB', { 
            url: `/${payload.context}/all` 
        })

        return task

    },
    async getItemFromDB({ dispatch }, payload){

        let task = await dispatch('makeRequestToDB', { 
            url: `/${payload.context}/get/${payload.id}` 
        })

        return task

    },
    async makeRequestToDB({}, payload){
        
        let success = false

        let response = null
        let error = null

        try {
            
            response = await axios.request({
                method: payload.method || 'get',
                baseURL: DATABASE_API_BASE_URL,
                headers: API_REQUEST_HEADERS,
                params: payload.params || {},
                data: payload.data || {},
                url: payload.url || '/'
            })

            success = response && response.data !== ''

        } catch (err) {

            error = err

            success = false

            if (process.env.NODE_ENV === 'development') {

                if (error.response) {
                
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);

                } else if (error.request) {
                
                    console.log(error.request);
                
                } else {
                
                    console.log('Error', error.message);
                
                }
                
                console.log(error.config);

            }

        }
        
        return {            
            success: success,
            failure: !success,
            data: response ? response.data : null,
            error: error
        }

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}