import _ from 'lodash'

import { WEBSITE_INFO_CONTEXT } from '@/utils/constants'
import { WebsiteInfo } from '@/utils/enums'

const state = () => ({
    websiteInfo: []
})

const getters = {
    getWebsiteInfo: (state) => (name) => {

        return state.websiteInfo.find(s => s.name === name)

    },
    getWebsiteInfoValue: (state) => (name) => {

        let data = state.websiteInfo.find(s => s.name === name)
        
        return data ? data.value : ''

    },
}

const mutations = {
    
}

const actions = {
    async initWebsiteInfoDB({ dispatch, state }){

        await dispatch('getAllWebsiteInfo')

        _.forIn(WebsiteInfo, async function(value, key) {

            if (state.websiteInfo && _.isArray(state.websiteInfo)) {
                
                let index = state.websiteInfo.findIndex(s => s.name === value.NAME)
                
                if (index === -1) {

                    await dispatch('addWebsiteInfo', {
                        name: value.NAME,
                        value: value.DEFAULT
                    })

                }

            } else {
                
                await dispatch('addWebsiteInfo', {
                    name: value.NAME,
                    value: value.DEFAULT
                })

            }

        })

        await dispatch('getAllWebsiteInfo')

    },
    async getAllWebsiteInfo({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WEBSITE_INFO_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.websiteInfo = task.data 
        else task.data = state.websiteInfo

        return task

    },
    async updateWebsiteInfo({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WEBSITE_INFO_CONTEXT}/update`,
            method: 'put',
            data: data
        }, { root: true })

        return task

    },
    async addWebsiteInfo({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WEBSITE_INFO_CONTEXT}/add`,
            method: 'post',
            data: data 
        }, { root: true })

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}