import { WIDGET_CONTEXT } from '@/utils/constants'

const state = () => ({
    widgets: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async addUniqueWidget({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WIDGET_CONTEXT}/add/unique`,
            method: 'post',
            data: data
        }, { root: true })

        return task

    },
    async addReplicableWidget({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WIDGET_CONTEXT}/add/replicable`,
            method: 'post',
            data: data
        }, { root: true })

        return task

    },
    async getAllWidgets({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${WIDGET_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.widgets = task.data 
        else task.data = state.widgets

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}