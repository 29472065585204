import moment from 'moment'

export function sleep(ms) {
    
    return new Promise(resolve => setTimeout(resolve, ms))
    
}

export function getCurrentTimestamp(){
    
    return Math.round(new Date() / 1000)

}

export function getDateFromTimestamp(date, {locale = 'en', pattern = 'MM/DD/YYYY hh:mm'} = {}){

    return moment(new Date(date.seconds * 1000)).locale(getCurrentLocale(locale)).format(pattern)    

}

export function reformatDate(date, {inPattern = 'YYYY-MM-DD', outPattern = 'DD MMM YYYY', locale = "fr"} = {}){

    return moment(date, inPattern).locale(locale).format(outPattern)

}

export function formatDate(date, {outPattern = 'DD MMM YYYY', locale = "fr"} = {}){

    return moment(date).locale(locale).format(outPattern)

}

export function differenceWithToday(date, {pattern = 'YYYY-MM-DD', unit = 'days'} = {}){

    return moment(date, pattern).diff(moment(), unit)

}

export function differenceBetweenDates(startDate, endDate, {startDatePattern = 'YYYY-MM-DD', endDatePattern = 'YYYY-MM-DD', unit = 'days'} = {}){
    
    let start = moment(startDate, startDatePattern)
    let end = moment(endDate, endDatePattern)

    return start.diff(end, unit)

}