import { USERS_CONTEXT } from '@/utils/constants'

const state = () => ({
    users: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async checkUser({ dispatch }, uid){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${USERS_CONTEXT}/check/${uid}`, 
        }, { root: true })

        return task

    },
    async getAllUsers({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${USERS_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.users = task.data 
        else task.data = state.users

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}