import { mapState, mapActions } from 'vuex'

import { STORAGE_API_BASE_URL } from '@/utils/constants'

export default{
    computed: {
        ...mapState({
            pages: state => state.page.pages,
            menus: state => state.menu.menus,
            widgets: state => state.widget.widgets,
            menuItems: state => state.menu.menuItems,
            menuLocations: state => state.menu.menuLocations,
            widgetsJSONData: state => state.globals.widgetsJSONData,
            sectionsJSONData: state => state.globals.sectionsJSONData            
        })        
    },
    methods: {
        ...mapActions('globals', [ 
            'loadJSONData',

            'addItemInDB', 
            'getItemFromDB',
            'updateItemInDB', 
            'deleteItemInDB', 
            'getAllItemsFromDB' 
        ]),
        makeLink(part) {
            if (part){

                if (this.$validator.isURL(part)) return part
                else return STORAGE_API_BASE_URL + part

            } 
            
            return ''
        }
    },
}