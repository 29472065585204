import { DATA_CONTEXT } from '@/utils/constants'

const state = () => ({
    tablesBackups: [],
    tablesList: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async getTablesList({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${DATA_CONTEXT}/get/tables-list` 
        }, { root: true })

        if (task.success) state.tablesList = task.data 
        else task.data = state.tablesList

        return task

    },
    async getTablesBackups({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${DATA_CONTEXT}/get/tables-backups` 
        }, { root: true })

        if (task.success) state.tablesBackups = task.data 
        else task.data = state.tablesBackups

        return task

    },
    async makeTablesBackup({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${DATA_CONTEXT}/backup`,
            method: 'post',
            data: data 
        }, { root: true })

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}