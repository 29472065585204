import { MENU_CONTEXT } from '@/utils/constants'

const state = () => ({
    menus: [],
    menuItems: [],
    menuLocations: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async getAllMenus({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.menus = task.data 
        else task.data = state.menus

        return task

    },
    async getAllMenuItems({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/items/all` 
        }, { root: true })

        if (task.success) state.menuItems = task.data 
        else task.data = state.menuItems

        return task

    },
    async getAllMenuLocations({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/locations` 
        }, { root: true })

        if (task.success) state.menuLocations = task.data 
        else task.data = state.menuLocations

        return task

    },
    async updateMenuLocations({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/locations/update`,
            method: 'put',
            data: data 
        }, { root: true })

        return task

    },
    async getMenuItemsByParent({ dispatch }, parent){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/items/parent/${parent}`,
        }, { root: true })

        return task

    },
    async addMenuItem({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/item/add`,
            method: 'post',
            data: data
        }, { root: true })

        return task

    },
    async updateMenuItem({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/item/update/${payload.id}`,
            method: 'put',
            data: payload.data
        }, { root: true })

        return task

    },
    async deleteMenuItem({ dispatch }, id){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MENU_CONTEXT}/item/delete/${id}`,
            method: 'delete'
        }, { root: true })

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}