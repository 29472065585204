import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

const routes = [
  {        
    path: '/',
    name: 'container',
    component: () => import('@/views/Container.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  store.commit('globals/updatePreviousRouteName', from.name, { root: true })
  store.commit('globals/updateNextRouteName', to.name, { root: true })

  store.commit('session/updateSession', { root: true })

  next()

})

export default router
