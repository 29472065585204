import { getBaseUrl } from '@/utils/functions'

// General
export var APP_BASE_URL = process.env.NODE_ENV == 'development' ? 'http://zenith.dev.local' : getBaseUrl()
export const X_API_KEY = 'b47e8c19-9e29-4287-acb1-4f34481402a0'
export const API_REQUEST_HEADERS = {
    'X-API-Key': X_API_KEY
}

// DB Api
export var DATABASE_API_BASE_URL = APP_BASE_URL + '/breeze-server/db-api/'
// DB APi Contexts
export const AUTH_CONTEXT = 'auth'
export const DATA_CONTEXT = 'data'
export const MENU_CONTEXT = 'menu'
export const PAGE_CONTEXT = 'page'
export const MEDIA_CONTEXT = 'media'
export const USERS_CONTEXT = 'users'
export const ACCESS_CONTEXT = 'access'
export const WIDGET_CONTEXT = 'widget'
export const SETTINGS_CONTEXT = 'settings'
export const WEBSITE_INFO_CONTEXT = 'website-info'

// Storage Api
export var STORAGE_API_BASE_URL = APP_BASE_URL + '/breeze-server/sto-api/'

// JSON Api
export var JSON_API_BASE_URL = APP_BASE_URL + '/breeze-server/json-api/'

// Client IP Api
export var CLIENT_IP_API_BASE_URL = APP_BASE_URL + '/breeze-server/client-ip-api/'

// IP Location Api
export var IP_LOCATION_API_BASE_URL = APP_BASE_URL + '/breeze-server/ip-location-api/'

// Send Email Api
export var SEND_EMAIL_API_BASE_URL = APP_BASE_URL + '/breeze-server/send-email-api/'

// Cookie KeyNames
export const LANG_COOKIE = 'Lang'

// Date Format List
export const DATE_FORMAT_LIST = ['DD MMM YYYY', 'DD MMMM YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'YYYY/MM/DD']