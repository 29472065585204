import _ from 'lodash'

import { SETTINGS_CONTEXT } from '@/utils/constants'
import { Settings, VarTypes } from '@/utils/enums'

const state = () => ({
    settings: []
})

const getters = {
    getSetting: (state) => (name) => {

        return state.settings.find(s => s.name === name)

    },
    getSettingValue: (state) => (name, type = VarTypes.STRING) => {

        let setting = state.settings.find(s => s.name === name)
        
        let value = ''

        switch (type) {
            case VarTypes.NUMBER:

                value = setting && parseInt(setting.value) 
                      ? parseInt(setting.value) 
                      : 0        

                break

            case VarTypes.BOOLEAN:
                
                value = setting && parseInt(setting.value) > 0                

                break

            case VarTypes.OBJECT:
                
                value = setting && setting.value ? JSON.parse(setting.value) : {}                

                break

            case VarTypes.ARRAY:
            
                value = setting && setting.value ? JSON.parse(setting.value) : []                

                break
        
            default:

                value = setting && setting.value 
                      ? setting.value 
                      : ''

                break
        }
        
        return value

    },
}

const mutations = {
    
}

const actions = {
    async initSettingsDB({ dispatch, state }){

        await dispatch('getAllSettings')

        _.forIn(Settings, async function(value, key) {

            if (state.settings && _.isArray(state.settings)) {
                
                let index = state.settings.findIndex(s => s.name === value.NAME)
                
                if (index === -1) {

                    await dispatch('addSetting', {
                        name: value.NAME,
                        value: value.DEFAULT
                    })

                }

            } else {
                
                await dispatch('addSetting', {
                    name: value.NAME,
                    value: value.DEFAULT
                })

            }

        })

        await dispatch('getAllSettings')

    },
    async getAllSettings({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.settings = task.data 
        else task.data = state.settings

        return task

    },
    async updateSetting({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/update`,
            method: 'put',
            data: data
        }, { root: true })

        return task

    },
    async addSetting({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/add`,
            method: 'post',
            data: data 
        }, { root: true })

        return task

    },
    async updateFolder({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/update/folder`,
            method: 'put',
            data: data
        }, { root: true })

        return task

    },
    async updateJSON({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/update/json`,
            method: 'put',
            data: data
        }, { root: true })

        return task

    },
    async fetchDbConfig({ dispatch }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${SETTINGS_CONTEXT}/fetch/db-config` 
        }, { root: true })

        return task

    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}