<template>
  <router-view/>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  created() {
    
    this.setLoadingSessionId()
    this.startSession()

    this.initWebsiteInfo()

    this.getAllPages()
    this.getAllMenus()
    this.loadJSONData()
    this.getAllWidgets()
    this.getAllMenuItems()
    this.getAllMenuLocations()

  },
  methods: {
    ...mapMutations({
      startSession: 'session/startSession',
      setLoadingSessionId: 'session/setLoadingSessionId'
    }),
    ...mapActions({      
      initWebsiteInfo: 'websiteInfo/initWebsiteInfoDB',
      getAllMenuLocations: 'menu/getAllMenuLocations',
      getAllMenuItems: 'menu/getAllMenuItems',
      getAllWidgets: 'widget/getAllWidgets',
      loadJSONData: 'globals/loadJSONData',
      getAllMenus: 'menu/getAllMenus',
      getAllPages: 'page/getAllPages'      
    })    
  }
}
</script>

<style src="@/assets/styles/app.css"></style>
