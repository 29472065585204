import { PAGE_CONTEXT } from '@/utils/constants'

const state = () => ({
    pages: []
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async updateHomePage({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${PAGE_CONTEXT}/update/is-home/${payload.id}/${payload.value}`,
            method: 'put',
        }, { root: true })

        return task

    },
    async getAllPages({ dispatch, state }){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${PAGE_CONTEXT}/all` 
        }, { root: true })

        if (task.success) state.pages = task.data 
        else task.data = state.pages

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}