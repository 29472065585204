import { MEDIA_CONTEXT } from '@/utils/constants'

import { Settings, VarTypes } from '@/utils/enums'

const state = () => ({
    
})

const getters = {
    
}

const mutations = {
    
}

const actions = {
    async generateThumbnails({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/generate/thumbnails`,
            method: 'post',
            data: data
        }, { root: true })

        return task

    },
    async generateThumbnail({ dispatch }, data){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/generate/thumbnail`,
            method: 'post',
            data: data
        }, { root: true })

        return task

    },
    async checkLib({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/check/${payload.lib}`
        }, { root: true })

        return task

    },
    async getFolderMedias({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/get/files/${payload.folder}`
        }, { root: true })

        return task

    },
    async deleteMediaFile({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/delete-file/${payload.folder}/${payload.file}`,
            method: 'delete'
        }, { root: true })

        return task

    },
    async deleteMedia({ rootGetters, dispatch }, payload){

        let keepFile = rootGetters['settings/getSettingValue'](Settings.KEEP_DELETED_FILES.NAME, VarTypes.NUMBER)

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/delete/${payload.id}/${keepFile}`,
            method: 'delete'
        }, { root: true })

        return task

    },
    async getMediasPage({ dispatch }, payload){

        let task = await dispatch('globals/makeRequestToDB', { 
            url: `/${MEDIA_CONTEXT}/get/page/${payload.offset}/${payload.limit}`
        }, { root: true })

        return task

    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}