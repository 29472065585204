import _ from 'lodash'
import colorLib from '@kurkle/color'
import imageCompression from 'browser-image-compression'

export function generateAvatarImage(text, foregroundColor, backgroundColor) {

    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")

    canvas.width = 200
    canvas.height = 200

    context.fillStyle = backgroundColor
    context.fillRect(0, 0, canvas.width, canvas.height)

    context.font = "bold 100px Assistant"
    context.fillStyle = foregroundColor
    context.textAlign = "center"
    context.textBaseline = "middle"
    context.fillText(text, canvas.width / 2, canvas.height / 2)

    return canvas.toDataURL("image/png")

}

export function getNameInitials(name){

    let nameSplit = name.split(" "),
        initials = nameSplit.length > 1 
                    ? nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase()
                    : nameSplit[0].charAt(0).toUpperCase()

    return initials

}

export function generateAvatarFromName(name, canvasId, fontSize){

    var colours = ["#1abc9c", "#2ecc71", "#3498db", "#9b59b6", "#34495e", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#2c3e50", "#f1c40f", "#e67e22", "#e74c3c", "#95a5a6", "#f39c12", "#d35400", "#c0392b", "#bdc3c7", "#7f8c8d"]

    var initials = getNameInitials(name)

    var charIndex = initials.charCodeAt(0) - 65,
        colourIndex = charIndex % 19

    var canvas = document.getElementById(canvasId)

    if (canvas){
        var context = canvas.getContext("2d")

        var canvasWidth = $(canvas).attr("width"),
            canvasHeight = $(canvas).attr("height"),
            canvasCssWidth = canvasWidth,
            canvasCssHeight = canvasHeight

        if (window.devicePixelRatio) {
            $(canvas).attr("width", canvasWidth * window.devicePixelRatio)
            $(canvas).attr("height", canvasHeight * window.devicePixelRatio)
            $(canvas).css("width", canvasCssWidth)
            $(canvas).css("height", canvasCssHeight)
            context.scale(window.devicePixelRatio, window.devicePixelRatio)
        }

        context.fillStyle = colours[colourIndex]
        context.fillRect (0, 0, canvas.width, canvas.height)
        context.font = fontSize + "px Arial"
        context.textAlign = "center"
        context.fillStyle = "#FFF"
        context.fillText(initials, canvasCssWidth / 2, canvasCssHeight / 1.5)
    }    
    
}

export function getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min
}

export function removeTrailingSlash(path){
    if (path.length > 1 && path.charAt(path.length - 1) === '/') {
      return path.substring(0, path.length - 1)  
    }
    return path
}

export function calculateAverage(list, {field = 'value', toInt = true} = {}){

    if (list && list.length > 0) {
        let sum = 0

        list.forEach(item => {
            sum += toInt ? parseInt(item[field]) : item[field]
        })

        return sum / list.length
    }

    return 0

}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {

    var R = 6371
    var dLat = deg2rad(lat2-lat1)
    var dLon = deg2rad(lon2-lon1) 
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2)
       
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    var d = R * c

    return d

}
  
export function deg2rad(deg) {
    
    return deg * (Math.PI/180)

}

export function isExternal(path) {
    
    return /^(https?:|mailto:|tel:)/.test(path)
    
}

export function formatTimeFromSeconds(duration, { separator = ":" } = {}) {   
    
    var hrs = ~~(duration / 3600)
    var mins = ~~((duration % 3600) / 60)
    var secs = ~~duration % 60

    var ret = ""

    if (hrs > 0) {
        ret += "" + strPadLeft(hrs, '0', 2) + separator + (mins < 10 ? "00" : "")
    }

    ret += "" + strPadLeft(mins, '0', 2) + separator + (secs < 10 ? "00" : "")
    ret += "" + strPadLeft(secs, '0', 2)

    return ret

}

export function strPadLeft(string, pad, length) {

    return (new Array(length + 1).join(pad) + string).slice(-length)

}

export function capitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
    
}

export function transparentize(value, opacity) {
    
    var alpha = opacity === undefined ? 0.5 : 1 - opacity

    return colorLib(value).alpha(alpha).rgbString()

}

export function genPassword(length = 10, {letters = true, numbers = true, symbols = false} = {}) {
    
    if (!letters && !numbers && !symbols) return ''

    let base = [],    
        password = "",
        numbersBase = "0123456789".split(''),
        symbolsBase = "!@#$%^&*()".split(''),
        lettersBase = "abcdefghijklmnopqrstuvwxyz".split('')

    if (letters) base.push(...lettersBase)
    if (numbers) base.push(...numbersBase)
    if (symbols) base.push(...symbolsBase)

    base = _.shuffle(base)
    
    for (let i = 0; i < length; i++) {
    
        let randomNumber = Math.floor(Math.random() * base.length)
 
        let char = lettersBase.includes(base[randomNumber]) && Math.floor(Math.random() * 2) === 0 
                 ? base[randomNumber].toUpperCase() 
                 : base[randomNumber]

        password += char

    }
    
    return password

}

export function extractUppercaseWords(input){

    return input.match(/(\b[A-Z]['A-Z]+|\b[A-Z]\b)/g)

}

export function extractLowercaseWords(input){

    return input.match(/(\b[a-z]['a-z]+|\b[a-z]\b)/g)

}

export function genRandomColor(){
    
    let letters = '0123456789ABCDEF', color = '#'

    for (let i = 0; i < 6; i++) {        
        color += letters[Math.floor(Math.random() * 16)]
    }

    return color

}

export function dataUrltoBlob(dataurl) {

    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)

    while(n--){
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new Blob([u8arr], {type:mime})

}

export function dataUrlToFile(dataurl, { filename = 'file.jpg', type = 'image/jpeg' } = {}){

    return new File([dataUrltoBlob(dataurl)], filename, { type: type, lastModified: new Date() })

}

export function toArrayOfPureJsObjects(array){

    return array.map((obj) => { return Object.assign({}, obj) })

}

export function humanFileSize(bytes, si = false, dp = 1) {
    
    const thresh = si ? 1000 : 1024
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B'
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    let u = -1
    const r = 10**dp
  
    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
  
    return bytes.toFixed(dp) + ' ' + units[u]

}

export function downloadByUrl(url) {
    
    var element = document.createElement('a')
    element.setAttribute('href', url)
  
    element.style.display = 'none'
    document.body.appendChild(element)
  
    element.click()
  
    document.body.removeChild(element)

}

export async function parseINIString(data){

    var regex = {
        section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
        param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
        comment: /^\s*;.*$/
    }
    var value = {}
    var lines = data.split(/[\r\n]+/)
    var section = null

    lines.forEach(function(line){
        if(regex.comment.test(line)){
            return
        }else if(regex.param.test(line)){
            var match = line.match(regex.param)
            if(section){
                value[section][match[1]] = match[2]
            }else{
                value[match[1]] = match[2]
            }
        }else if(regex.section.test(line)){
            var match = line.match(regex.section)
            value[match[1]] = {}
            section = match[1]
        }else if(line.length == 0 && section){
            section = null
        }
    })

    return value
    
}

export async function compressImage(file, { maxSizeMB = 1, maxWidthOrHeight = 1920 } = {}){

    let extensions = ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg']

    let fileExt = file.name.split('.').pop()

    const options = {
        maxSizeMB: maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight
    }

    if (extensions.includes(fileExt.toLowerCase())) {

        let result = await imageCompression(file, options)

        if (result instanceof File) return result
        else if (result instanceof Blob) return new File([result], file.name)

    }

    return file

}

export function getBaseUrl(){

    return window.location.protocol + '//' + window.location.hostname

}

export function getPageUrl(){

    return getBaseUrl() + window.location.pathname

}

export function openWindowWithJS(url, data, { target = '_self', method = 'POST' } = {}) {

    var form = document.createElement("form")
    form.target = target
    form.method = method
    form.action = url
    form.style.display = "none"

    for (var key in data) {
        var input = document.createElement("input")
        input.type = "hidden"
        input.name = key
        input.value = data[key]
        form.appendChild(input)
    }

    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)

}

export function loadImageMeta(url){

    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = url
    })

}